import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useMemo } from "react";

import StandardButton from "../../components/Buttons/StandardButton";
import RatingStars from "../../components/Rating/RatingStars";
import colours from "../../styles/colours";
import gStyles from "../../styles/GenericStyles";
import {
  getRatingButtonStyles,
  getRatingIcon,
  getRatingVariationColor,
  podcastVerbLabels,
} from "../../utils/rating";

import { useStyles } from "hooks/useStyles";

const rateButtonStyles = {
  button: {
    height: 40,
    padding: ".75rem 0",
  },
};

const baseStyles = {
  ratingStars: {
    fontSize: "1em",
  },
};

const EntityRatingButton = (props) => {
  const { rating, onClick, onRatingsChange, verbLabels, passedButtonStyles } =
    props;

  // TODO: Just used this for testing, but we could maybe pass this in if we need it again
  const forceShowStars = false;

  const ratingStyles = {
    ratingText: {
      color: getRatingVariationColor(rating),
    },
    ratingIcon: {
      color: getRatingVariationColor(rating),
      marginRight: "0.5em",
      transform: "scale(1.4)",
      position: "relative",
      display: "inline-block",
    },
  };

  const { styles } = useStyles([baseStyles, ratingStyles], props);

  const starLabel = (
    <span className={css(styles.ratingStars)}>
      <RatingStars rating={rating} onChange={onRatingsChange} />
    </span>
  );

  // eslint-disable-next-line max-len
  const youRated = (rating) => (
    <span>
      <span className={css(styles.ratingIcon)}>
        <FontAwesomeIcon icon={getRatingIcon(rating)}></FontAwesomeIcon>
      </span>{" "}
      You{" "}
      <span className={css(styles.ratingText)}>
        {verbLabels[rating].toLowerCase()}
      </span>{" "}
      it
    </span>
  );

  const variation = getRatingVariationColor(rating);

  const changeRatingStyles = useMemo(() => {
    const ratingStyles = getRatingButtonStyles(rating);

    return {
      ...passedButtonStyles,
      button: {
        ...gStyles.avalonBold,
        ...(ratingStyles && null),
        borderRadius: 100,
        color: colours.deepGrey,
        height: "3em",
      },
    };
  }, [rating, passedButtonStyles]);

  return rating ? (
    <StandardButton
      variation={variation ? null : "white"}
      onClick={onClick}
      fontSize=".8125rem"
      label={youRated(rating)}
      hoverLabel="Change Rating"
      flat
      customStyles={changeRatingStyles}
      key="spreadTheWordRateChangeButton"
      rounded
    />
  ) : (
    <StandardButton
      variation="white"
      fontSize=".8125rem"
      onClick={onClick}
      label={forceShowStars ? starLabel : "Rate"}
      hoverLabel={starLabel}
      flat
      customStyles={{ rateButtonStyles, ...passedButtonStyles }}
      outerComponent="div"
      key="spreadTheWordRateButton"
      rounded
    />
  );
};

EntityRatingButton.propTypes = {
  rating: PropTypes.number,
  onClick: PropTypes.func,
  onRatingsChange: PropTypes.func,
  verbLabels: PropTypes.object,
};

EntityRatingButton.defaultProps = {
  rating: null,
  onClick: null,
  onRatingsChange: null,
  verbLabels: podcastVerbLabels,
};

export default EntityRatingButton;
